import { Component, ElementRef, HostListener, ViewChild, PLATFORM_ID, Inject, ChangeDetectorRef, viewChildren, QueryList, ViewChildren, inject,  OnInit, AfterViewInit } from '@angular/core';
import { Renderer2 } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { DropdownService } from '../../services/dropdown.service';
import { SharedService } from '../../services/shared.service';
import { MainHomeService } from '../../services/main-home.service';
import { ArticleService } from '../../services/article.service';
import { SearchContextService } from '../../services/search-context-service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrl: './app-header.component.scss'
})


export class AppHeaderComponent implements OnInit, AfterViewInit {
  // @ViewChildren('megamenuAcademy') megamenuAcademy!: QueryList<ElementRef>;
  // @ViewChildren('dropdownMenuAcademy') dropdownMenus: QueryList<ElementRef>;
  // @ViewChild('megamenuPartners') megamenuPartners: ElementRef<HTMLElement> | undefined;
  // @ViewChild('dropdownMenuPartners') dropdownMenuPartners: ElementRef<HTMLElement> | undefined;
  // @ViewChild('megamenuAbout') megamenuAbout: ElementRef<HTMLElement> | undefined;
  // @ViewChild('dropdownMenuAbout') dropdownMenuAbout: ElementRef<HTMLElement> | undefined;
  // @ViewChild('dropdownMenuScientific') dropdownMenuScientific: ElementRef<HTMLElement> | undefined;
  @ViewChild('navbarContainer') navbarContainer!: ElementRef<HTMLElement>;
  @ViewChild('dropdownScientific') dropdownScientific: ElementRef<HTMLElement> | undefined;
  @ViewChild('joinBtn') joinBtn: ElementRef;
  @ViewChild('logoImage') logoImageTag: ElementRef;
  @ViewChild('navBar') navBar: ElementRef;
  screenWidth: number;
  mobileView = false;
  isMenuOpen = false;
  logoImage = '';
  valueSet = false;
  menuOpenAcademy: boolean = false;
  menuOpenPartners: boolean = false;
  menuOpenAbout: boolean = false;
  menuOpenScientific = false;
  menus: any[] = [];
  menuStructure: any = {};
  categoryContent: any;
  currentMenu = 'Home';
  isModalOpen = false;
  menuStates: { [key: string]: boolean } = {};
  isHomePage: boolean = false;
  menuHeadingLoading = true;
  menuContentLoading = true;
  loaderMenu = new Array(8).fill(0);
  private readonly searchContextService = inject(SearchContextService);
  url: string = '';
  urlArray:string[]=[];
  activeUrl: string = '';
  subMenuActive = false;
  clickedSubMenuParentName = '';
  megaMenuActive: boolean;
  constructor(@Inject(PLATFORM_ID) private platformId: Object, private cdr: ChangeDetectorRef,
    private sharedService: SharedService, private el: ElementRef, private dropdownService: DropdownService,
    private mainService: MainHomeService, private articleService: ArticleService, public router: Router, private route: ActivatedRoute) {
    this.screenWidth = isPlatformBrowser(this.platformId) ? window.innerWidth : 0;
  }
  ngOnInit() {

    if (isPlatformBrowser(this.platformId)) {
      this.checkResponsive();
      window.addEventListener('scroll', this.onScroll.bind(this));
      this.sharedService.getAllThemsettingsData().subscribe(response => { // console.log("response>> ", response);
        this.logoImage = response.data.journal_logo;
        this.valueSet = true;
        if (isPlatformBrowser(this.platformId)) {
          
            Object.entries(response.data.theme_colors).map(([key, value]) => {
                const element = "--" + key;
                const val = value as string;
                document.documentElement.style.setProperty(element, val);
                
            });
        }

      })

    }
    this.getMenuDetails();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.isHomePage = this.url === '/';
        this.urlArray = event.urlAfterRedirects.split('/');
        for (const item of this.menus) {
          const pattern = new RegExp(item.identifier.replace(/_/g, '-'));
          if (pattern.test(this.urlArray[1]) || pattern.test(this.urlArray[2])) {
            this.clickedSubMenuParentName = item.identifier;
            this.menuStates[item.identifier]?this.closeMenu(item.identifier):null;
            break;
          }
        }
      }
    });
  }

  onScroll() {
    const logo = document.querySelector('#logo');
    const logo_text = document.querySelector('#logoText');
    if (window.scrollY > 0) {
      this.navbarContainer.nativeElement.classList.add('scroll-shadow');

      if ( window.scrollY > 100) {
        logo.setAttribute('style', 'width: 145.14px; height: 65px; bottom:-2px; transition: height 0.3s ease;');
        logo_text.setAttribute('style', 'font-size: 24px !important;');
        if (this.navbarContainer?.nativeElement && this.navBar?.nativeElement) {
          this.navbarContainer.nativeElement.style.height = this.navBar.nativeElement.offsetHeight + 'px';
        }

      } 

    } else {
      logo.setAttribute('style', 'bottom:22px');
      logo_text.setAttribute('style', 'font-size: 28px !important;');
      this.navbarContainer.nativeElement.classList.remove('scroll-shadow');

    }

  }

  closeMenu(menu: string) {
    this.menuStates[menu] = false;
    // this.resetSearchContext();
    setTimeout(() => {
      this.dropdownService.closeMenu(menu);
    }, 200);


  }

  cancelCloseMenu(menu: string) {
    // this.menuHeadingLoading=true;
    // this.menuContentLoading=true;
    this.menuStates[menu] = true;
    this.dropdownService.cancelCloseMenu(menu);
  }
  toggleMobileView() {
    this.isMenuOpen = !this.isMenuOpen;
    this.mobileView = !this.mobileView;
    if (!this.isMenuOpen) {
      this.menus.forEach(e => {
        this.menuStates[e.identifier] = false;
      });
    }
  }

  openMenu(menu: string) {
    // this.menuHeadingLoading=true;
    // this.menuContentLoading=true;
    // this.closeOtherMenu(menu);
    if (!this.menuStates[menu]) this.menuStates[menu] = true;
    this.dropdownService.openMenu(menu);
    // this.getCategoryContent(menu);


  }
  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    if (isPlatformBrowser(this.platformId)) {
      this.screenWidth = window.innerWidth;
      this.checkResponsive();
      if (this.screenWidth >= 992 && this.isMenuOpen) {
        this.toggleMobileView();
      }
    }
  }

  checkResponsive() {
    if (this.screenWidth <= 992) {
    } else {
      this.mobileView = false;
    }
  }
  // toggleMenu(menuType: string) {
  //   if (menuType === 'academy') {
  //     this.menuOpenAcademy = !this.menuOpenAcademy;
  //     if (this.menuOpenAcademy) {
  //       this.menuOpenScientific = false;
  //     }
  //   } else if (menuType === 'scientific') {
  //     this.menuOpenScientific = !this.menuOpenScientific;
  //   } else if (menuType === 'about') {
  //     this.menuOpenAbout = !this.menuOpenAbout;
  //   }
  //   this.updateDropdown(this.dropdownMenuAcademy, this.menuOpenAcademy);
  //   // this.updateDropdown(this.dropdownMenuScientific, this.menuOpenScientific);
  //   // this.updateDropdown(this.dropdownMenuAbout, this.menuOpenAbout);
  // }

  // private updateDropdown(element: ElementRef | undefined, isOpen: boolean) {
  //   if (element) {
  //     const nativeElement = element.nativeElement;
  //     if (isOpen) {

  //       this.renderer.addClass(nativeElement, 'show');
  //     } else {
  //       this.renderer.removeClass(nativeElement, 'show');
  //     }
  //   }
  // }
  // isMenuOpenMenu(menu: string): boolean {
  //   return this.dropdownService.isOpen(menu);

  // }
  // cancelCloseMenu(menu: string) {
  //   this.dropdownService.cancelCloseMenu(menu);
  // }
  isMenuOpenMenu(menu: string): boolean {
    return this.dropdownService.isOpen(menu);
  }
  toggleMenu(identifier: string) {
    this.menuStates[identifier] = !this.menuStates[identifier];
  }

  isMenuOpenfunction(identifier: string): boolean {
    return !!this.menuStates[identifier];
  }

  getMenuDetails() {
    this.mainService.getMenuDetails().subscribe((response: any) => {
      this.menus = response;

      this.menus.forEach(e => {
        this.menuStates[e.identifier] = false;

        if (e.subMenus && e.subMenus.length > 0) {
          const data = {
            category: e.identifier
          }
          this.articleService.getCategoryContent(data).subscribe((categoryResponse: any) => {
            this.categoryContent = categoryResponse.data[0];
            e['categoryContent'] = this.categoryContent;

            this.menuHeadingLoading = false;
            this.menuContentLoading = false;
          });
        }
      });
    });
  }


  sortMenu(menu: any) {
    // Sort the menu items and their submenus based on the sort_order
    menu.subMenus.sort((a: any, b: any) => a.sort_order - b.sort_order);
    menu.subMenus.forEach((subMenu: any) => this.sortMenu(subMenu));
  }

  // getCategoryContent(category: string) {
  //   this.menuHeadingLoading = true;
  //   this.menuContentLoading = true;
  //   const data = {
  //     category: category
  //   }
  //   this.articleService.getCategoryContent(data).subscribe((response: any) => {
  //     this.categoryContent = response.data[0];

  //     this.menuHeadingLoading = false;
  //     this.menuContentLoading = false;
  //   });

  // }

  closeOtherMenu(menu: string) {
    // this.menuHeadingLoading=true;
    // this.menuContentLoading=true;
    for (let key in this.menuStates) {
      this.menuStates[key] = key === menu;
    }
  }
  logNavigation() {
    this.router.navigate(['/academy', 'scientific-surgery', 'randomized-clinical-trials']).then(success => {
    }).catch(error => {
    });
  }
  // getMenuValue(eachMenu: any) {
  //   this.getCategoryContent(eachMenu)

  // }


  openJoinModal() {
    this.isModalOpen = true;
  }

  closeJoinModal() {
    this.isModalOpen = false;
  }
  private resetSearchContext(): void {
    this.searchContextService.setSelectedSpecialities([]);
    this.searchContextService.setSelectedContentType([]);
    this.searchContextService.setSearchContent(undefined);
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }
  
  getClickedSubMenuParent(menu: any) {
   if(menu.url!==null) this.clickedSubMenuParentName = menu.identifier;
  }

}
