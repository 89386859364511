import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ArticleService } from '../services/article.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-index',
    templateUrl: './index.component.html',
    styleUrl: './index.component.scss'
})
export class IndexComponent implements OnInit {
    xmlData: string;
    parser!: DOMParser;
    xmlData_dupe: any;
    IndexLoading=true;
    @ViewChild('indexContent') contentElement: ElementRef<HTMLDivElement>;
    alphabets: string[] = Array.from({ length: 26 }, (_, i) => String.fromCharCode(65 + i));

    constructor(private articleService: ArticleService, private sanitizer: DomSanitizer) {
        this.parser = new DOMParser();
    }

    ngOnInit() {
        
        this.articleService.getIndexData().subscribe(result => {
            // this.xmlData = result.data;
            this.restructureXml(result.data);
        })

    }

    restructureXml(data: string) {
        if (data) {
            this.IndexLoading=false;
            const html = this.parser?.parseFromString(data, 'text/html');

            // to hide front tag
            const content = html?.querySelector("[class='front']");
            content?.classList.add('hide');

            html?.querySelectorAll("[class='sec']").forEach(node => {
                node.classList.remove('sec');
                node.classList.add('secIndex');
            })

            const serializer = new XMLSerializer();
            this.xmlData = serializer.serializeToString(html);
            this.xmlData_dupe = this.sanitizer.bypassSecurityTrustHtml(this.xmlData);
        }

    }

    focusToAlphabets(alphabet:string){
        
        this.contentElement?.nativeElement.querySelectorAll('.title').forEach(ele=>{
            if(ele.textContent == alphabet){
                ele.scrollIntoView({
                    block:'center',
                    behavior:'smooth'
                })
                ele.classList.add('highlightAlph');
                setTimeout(() => {
					ele.classList.remove('highlightAlph');
				}, 1000);
            }
        })
    }

}
