import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ChapterViewPageComponent } from './chapter/chapter-view/chapter-view-page.component';
import { PageNotFoundComponent } from './general/page-not-found/page-not-found.component';
import { ChapterSearchListComponent } from './chapter/chapter-search-list/chapter-search-list.component';

const routes: Routes = [
    { path: ':heading_id/:part_id/:article-link', component: ChapterViewPageComponent },
    // { path: 'page/contributors', component: ContributorsComponent },
    // { path: 'page/index', component: IndexComponent },
    { path: 'search', component: ChapterSearchListComponent },
    {
        path: '',
        loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
    },
    {
        path: 'page',
        loadChildren: () => import('./content/content.module').then(m => m.ContentModule)

    },
    { path: 'page-not-found', component: PageNotFoundComponent },
    { path: '**', redirectTo: 'page-not-found' }

];
@NgModule({
    imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }