import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ShareButton } from 'ngx-sharebuttons/button';

import { Component, ElementRef, Inject, OnDestroy, OnInit, PLATFORM_ID, Renderer2, ViewChild, AfterViewInit } from '@angular/core';

import { Subscription } from 'rxjs';
import { env } from '../../config/config';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { NgClass, NgFor, NgIf, NgStyle, isPlatformBrowser } from '@angular/common';
import { SharedService } from '../../services/shared.service';
import { ArticleService } from '../../services/article.service';
import { articleOutline, articleView, authors_data, imagePaths } from '../../interfaces/article';


@Component({
    standalone: true,
    selector: 'app-chapter-view-page',
    imports: [ShareButton, FontAwesomeModule, NgIf, NgFor, NgStyle, NgClass],
    templateUrl: './chapter-view-page.component.html',
    styleUrl: './chapter-view-page.component.scss'
})
export class ChapterViewPageComponent implements AfterViewInit {
    @ViewChild('contentLoaded') contentLoaded!: ElementRef<HTMLDivElement>;
    @ViewChild('introduction', { static: false }) introductionSection!: ElementRef;
    @ViewChild('modeOfAction', { static: false }) modeOfActionSection!: ElementRef;
    @ViewChild('dapsone', { static: false }) dapsoneSection!: ElementRef;
    @ViewChild('references', { static: false }) referencesSection!: ElementRef;

    sharedUrl = '';
    journal_id = '';
    subscriptions: Subscription[] = [];
    articleView: articleView;
    articleCoverImageUrl = '';
    surgical_content: any[] = [];
    category = '';
    article_link = '';
    parser!: DOMParser;
    html_new_content = '';
    authorImages: string[] = [];
    html_new_content_dupe: any;
    relatedArticle: any[] = [];
    download_url: string = '';
    download_pdf = '';
    activeViewFont = "normal";
    downloadText = '';
    categorydocheadloading = true;
    pageLoading = true;
    articleTitleLoading = true;
    coverImgLoading = true;
    authorViewLoading = true;
    articleDoiLoading = true;
    articleContetLoading = true;
    relatedArticleLoading = true;
    contentLoadingArray = new Array(8).fill(0);
    articleFilePath: string;
    heading: string;
    caption: string;
    body: string;
    isModalOpen = false;
    div_data: Element;
    activeSection: string = '';
    activeTab = 'tab1';
    chapter_info = false;
    imagePaths: imagePaths[] = [];
    articleOutline: articleOutline[] = [];
    tooltipStatus = '';
    fn_group = false;
    isFixed = false;
    containerWidth: number = 0;
    referencesHTML: string | undefined;
    activeOutlineId: string | null = null;
    constructor(

        private route: ActivatedRoute,
        private sanitizer: DomSanitizer,
        private renderer: Renderer2,
        private router: Router,
        private sharedService: SharedService,
        private articleService: ArticleService,
        // private modalservice: NgbModal
        @Inject(PLATFORM_ID) private platformId: any
    ) {
        if (isPlatformBrowser(this.platformId)) {
            this.parser = new DOMParser();
        }

    }

    ngOnInit() {
        const routesub = this.route.params.subscribe(() => {
            this.articleLoading();
        });
        this.subscriptions.push(routesub);
    }


    /**
    * function to get article view data
    */
    articleLoading() {
        this.journal_id = this.sharedService.journalId;        
        if (this.route.snapshot.params['category']) {
            this.category = this.route.snapshot.params['category'];
        }
        else {
            this.category = this.route.snapshot.params['subcategory'];

        }
        this.article_link = this.route.snapshot.params['article-link'];
        const queryParams = {
            journal_id: this.journal_id,
            category: '',
            article_link: this.article_link,
            preview_mode: ''
        };
        const viewSub = this.articleService.getArticleViewData(queryParams).subscribe(result => {
            this.articleView = result.data;
            
            this.authorViewLoading = false;
            this.articleFilePath = this.articleView.art_file_path;

            // this.storeAuthorImage();
            // this.joinSurgicalAndContent();
            this.modifyingHtmlContent(this.articleView?.html_content);

            // if (this.articleView.article_id) {
            //     const relatedParams = {
            //         journal_id: this.journal_id,
            //         category: this.category,
            //         article_id: this.articleView.article_id
            //     };
            //     const relatedSub = this.articleService.getRelatedArticleList(relatedParams).subscribe(result => {
            //         this.relatedArticle = result.data;
            //         this.relatedArticleLoading=false;

            //     })
            //     this.subscriptions.push(relatedSub);
            // }
            // this.categorydocheadloading=false;
            // this.pageLoading=false;
            // this.articleTitleLoading=false;
            // this.articleDoiLoading=false;



        });
        this.subscriptions.push(viewSub);

        this.sharedUrl = env.siteUrl + '/academy/' + this.category + '/' + this.article_link;
    }



    openDyslexic(mode: string) {
        this.activeViewFont = mode;
        if (mode == 'dyslexic') this.contentLoaded.nativeElement.classList.add('dyslexic-regular');
        else if (mode == 'normal') this.contentLoaded.nativeElement.classList.remove('dyslexic-regular');

    }

    openDownloadLink() {
        window.open(this.download_url, '_blank');
    }

    //to display modal and to navigate to reference
    refClick(event: Event) {
        const aTagEle = event.target as HTMLInputElement;
        const refAttrVal = aTagEle?.getAttribute("attr-ref-type") as string;


        if (aTagEle.classList.contains('graphic')) {  // Check if the clicked element has the 'graphic' class
            const href: string = aTagEle.getAttribute('href') as string;
            const result = href.substring(0, href.indexOf('.')); // Extract the content before the first period

            const figElements = document.querySelectorAll(".fig"); // Query all elements with class 'fig'

            if (figElements.length > 0) {
                figElements.forEach((eachFig) => {
                    const imgElement = eachFig.querySelector('img.graphic');
                    if (imgElement) {
                        const imgHref = imgElement.getAttribute('href');
                        if (imgHref && imgHref.includes(result)) {
                            this.div_data = eachFig; // Store the reference to the matched fig element
                        }
                    }
                });

            }
            else {
                this.div_data = aTagEle;
            }


            if (this.div_data) {
                // Clone the div_data to ensure the original remains in the DOM
                this.div_data = this.div_data.cloneNode(true) as HTMLElement;
                this.isModalOpen = true;
            }
        }
    }

    loadModal(event: Event) {

        const aTagEle = event.target as HTMLInputElement;
        this.div_data = aTagEle;
        if (this.div_data) {
            // Clone the div_data to ensure the original remains in the DOM
            this.div_data = this.div_data.cloneNode(true) as HTMLElement;
            this.isModalOpen = true;
        }
    }


    closeJoinModal() {
        this.isModalOpen = false;
    }

    showContent(section: string) {
        this.activeSection = section;
    }
    setActiveTab(tab: string) {
        this.activeTab = tab;

    }
    onTagClick(tag: { type: string, value: string }) {

        const queryParams: any = {
            // search: this.searchValue
        };

        if (tag.type === 'contentType') {
            queryParams['contentType'] = tag.value;
        } else if (tag.type === 'speciality') {
            queryParams['speciality'] = tag.value;
        } else if (tag.type === 'category') {
            queryParams['category'] = tag.value;
        }
        this.router.navigate(['/academy', this.category, 'page', '1'], { queryParams });

    }


    ngAfterViewInit() {
        // ViewChild elements are now available here
    }
    //   scrollToSection(section: string) {
    //     console.log(section);
    //     switch (section) {
    //       case 'introduction':
    //         this.introductionSection?.nativeElement.scrollIntoView({ behavior: 'smooth' });
    //         break;
    //       case 'modeOfAction':
    //         this.modeOfActionSection?.nativeElement.scrollIntoView({ behavior: 'smooth' });
    //         break;
    //       case 'dapsone':
    //         this.dapsoneSection?.nativeElement.scrollIntoView({ behavior: 'smooth' });
    //         break;
    //       case 'references':
    //         this.referencesSection?.nativeElement.scrollIntoView({ behavior: 'smooth' });
    //         break;
    //       default:
    //         console.warn('Unknown section:', section);
    //     }
    //   }
    scrollToSection(secId: string) {
        this.activeOutlineId = secId;
        const sectionElement = document.querySelector(`[secid="${secId}"]`);
        if (sectionElement) {
            const yOffset = -150; // Adjust this offset as needed
            const y = sectionElement.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
        } else {
            console.warn('Section not found with secId:', secId);
        }
    }    
    
    downloadPDF(): void {
        window.open('https://www.internationaltextbookofleprosy.org/sites/default/files/ITL_5_2%20FINAL.pdf', '_blank');
    }

    onScroll(event: any) {

        const scrollPosition = window.scrollY;
        const targetElement = document.getElementById('chapter-tab');

        const containerElement = targetElement?.parentElement;
        const endContentElement = document.getElementById('article-content'); // The right column to detect when scrolling past it

        if (containerElement && endContentElement) {
            const targetPosition = containerElement.offsetTop;
            const endPosition = endContentElement.offsetHeight;
            this.containerWidth = containerElement.offsetWidth;

            // Check if the user has scrolled past the container but not beyond the main content
            if (scrollPosition >= targetPosition && scrollPosition <= endPosition) {
                this.isFixed = true; // Apply fixed position
            } else {
                this.isFixed = false; // Remove fixed position
            }

        }
    }

    returnAuthor(authors: authors_data[]) {
        let name: string[] = [];
        authors?.forEach(each_auth => {
            const eachname = each_auth.given_name + ' ' + each_auth.surname;
            name.push(eachname);
        })
        return name.join(', ');

    }

    modifyingHtmlContent(html_content: string) {
        if (html_content) {
            const html = this.parser?.parseFromString(html_content, 'text/html');

            //recreating html............

            // to hide front tag
            const content = html?.querySelector("[class='front']");
            content?.classList.add('hide');

            //=========== to hide doi section ====================//
            //getting all p tag
            const p_tags = html.querySelectorAll("[class='p']");
            const regex = /https?:\/\/doi\.org\/(\d+\.\d+)/;
            if (this.articleView?.doi) {
                const match = this.articleView?.doi.match(regex);
                // let extractedDOI='';
                if (match) {
                    var extractedDOI = `doi.org/${match[1]}`;
                }
            }

            const sec_tags = html.querySelectorAll('.sec');
            let sec_id = 0;
            sec_tags.forEach(each_sec => {
                // Check if the parent of this 'sec' is not another 'sec'
                if (!each_sec.closest('.sec > .sec')) {
                    sec_id++;
                    each_sec.setAttribute('secId', sec_id.toString());

                    let title = each_sec.querySelector('.title').textContent

                    const outline:articleOutline ={
                        title:title,
                        outlineId:sec_id.toString()
                    }

                    this.articleOutline.push(outline);
                }
            });
            



            //hiding doi tag
            p_tags.forEach(each_tag => {
                if (each_tag.innerHTML.includes(extractedDOI)) {
                    each_tag.classList.add("hide"); // add the 'hidden' class
                }

                // Find the span element within each_tag
                // const spanElement = each_tag.querySelector('span.bold') as HTMLElement;
                // if (spanElement) {
                //     spanElement.classList.add('section-title');
                //     // Perform additional actions if needed
                // }

                // if(each_tag.attr)
                if (each_tag.attributes[1]?.nodeValue == 'button-read-more' && each_tag.hasChildNodes()) {
                    each_tag.childNodes.forEach((node: any) => {
                        // if (node.textContent == 'Download PDF') {
                        this.downloadText = node?.textContent;
                        this.download_url = node?.href;
                        const download_array = this.download_url.split('/');
                        if (download_array.length > 0) {
                            const length = download_array?.length;
                            this.download_pdf = download_array[length - 1];
                            this.download_url = env.articleImage + this.articleFilePath + '/' + this.download_pdf;
                        }
                        each_tag.setAttribute('style', 'display: none;');
                        // }
                    })
                    each_tag.classList.add('read-more');
                }

            });



            //getting fig group
            const fig_group_tags = html.querySelectorAll("[class='fig']");
            fig_group_tags.forEach(fig_each_tag => {
                const fig_img_tag = fig_each_tag.querySelectorAll('img.graphic');
                fig_img_tag.forEach(img => {
                    const imgSrc = img.attributes[1]?.nodeValue; // Assuming 'src' is the attribute to match
                    if (imgSrc && this.authorImages.includes(imgSrc)) {
                        fig_each_tag.classList.add("hide");
                    }
                });
            })

            //restructuring images
            this.imagePaths = [];
            const fig_tags = Array.from(html.querySelectorAll("div.fig"))
                .filter(fig => !fig.classList.contains('hide'));
            Array.from(fig_tags).forEach((eachImage) => {
                const img = eachImage.querySelector('img') as HTMLElement;
                if (img) {
                    const caption = eachImage.querySelector("div.caption") as HTMLElement;
                    caption?.classList.remove('caption');
                    caption?.classList.add('fig-caption');
                    const fig_span = eachImage.querySelector("span.bold") as HTMLElement;
                    fig_span?.classList.remove('section-title');
                    fig_span?.classList.add('figure');
                    if (caption == null) {
                        eachImage.classList.remove('fig');
                        img.classList.add('small-image');
                    }
                    else {
                        img.setAttribute('width', '100%');
                        img.setAttribute('height', '100%');
                    }
                    const srcValue = img?.getAttribute('href') as string;
                    img.setAttribute(
                        'src',
                        env.apiUrl + 'storage/' + this.articleView.art_file_path +
                        '/' +
                        srcValue
                    );
                    const data: imagePaths = {
                        srcValue: srcValue,
                        imageUrl:
                            env.apiUrl + 'storage/' + this.articleView.art_file_path +
                            '/' +
                            srcValue
                    };

                    this.imagePaths.push(data);

                }


                //for loading vimeo widgets bjs-academyUrl
                const bjs_url = eachImage.querySelectorAll('[attr-ext-link-type="bjs-academyUrl"]');
                if (bjs_url.length > 0) {
                    bjs_url.forEach(eachUrl => {
                        const vimeoLink = eachUrl?.getAttribute('href');
                        if (vimeoLink) {
                            const vimeo_link_array = vimeoLink.split('/');
                            const vimeo_id = vimeo_link_array['4'];

                            if (vimeo_id != undefined) {
                                const embeded_vimeo_html = '<iframe class="bjsclass" loading="lazy" title="About Us Template" src="' + vimeoLink + '" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" data-ratio="0.562" data-width="750" data-height="450"></iframe>';
                                const divele = document.createElement('div');
                                divele?.classList.add('vimeo_widget');
                                divele.innerHTML = embeded_vimeo_html;
                                // eachVimeo.innerHTML = embeded_vimeo_html;
                                eachUrl.appendChild(divele);
                            }
                            eachImage.classList.remove('fig');
                            eachImage.classList.add('link');
                        }

                    })
                }

                //for loading vimeo widgets Url
                const url = eachImage.querySelectorAll('[attr-ext-link-type="Url"]');
                if (url.length > 0) {
                    url.forEach(eachUrl => {
                        const vimeoLink = eachUrl?.getAttribute('href');
                        if (vimeoLink) {
                            const vimeo_link_array = vimeoLink.split('/');
                            const vimeo_id = vimeo_link_array['4'];

                            if (vimeo_id != undefined) {
                                const embeded_vimeo_html = '<iframe class="bjsclass" loading="lazy" title="About Us Template" src="' + vimeoLink + '" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" data-ratio="0.562" data-width="750" data-height="450"></iframe>';
                                const divele = document.createElement('div');
                                divele?.classList.add('vimeo_widget');
                                divele.innerHTML = embeded_vimeo_html;
                                // eachVimeo.innerHTML = embeded_vimeo_html;
                                eachUrl.appendChild(divele);
                            }
                            eachImage.classList.remove('fig');
                            eachImage.classList.add('link');
                        }

                    })
                }

                //for loading vimeo widgets
                const vimeo = eachImage.querySelectorAll('[attr-ext-link-type="vimeoUrl"]');
                if (vimeo.length > 0) {
                    vimeo.forEach(eachVimeo => {
                        const vimeoLink = eachVimeo?.getAttribute('href');
                        if (vimeoLink) {
                            const vimeo_link_array = vimeoLink.split('/');
                            const vimeo_id = vimeo_link_array['4'];

                            if (vimeo_id != undefined) {
                                const embeded_vimeo_html = '<iframe class="iframeclass" loading="lazy" title="About Us Template" src="' + vimeoLink + '" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" data-ratio="0.562" data-width="750" data-height="450"></iframe>';
                                const divele = document.createElement('div');
                                divele?.classList.add('vimeo_widget');
                                divele.innerHTML = embeded_vimeo_html;
                                // eachVimeo.innerHTML = embeded_vimeo_html;
                                eachVimeo.appendChild(divele);
                            }
                            eachImage.classList.remove('fig');
                            eachImage.classList.add('link');
                        }
                    });
                }

                //for loading twitter links
                const twitter = eachImage.querySelectorAll('[attr-ext-link-type="twitterUrl"]');
                if (twitter.length > 0) {
                    twitter.forEach(eachTwitter => {
                        let twitterLink: any = eachTwitter?.getAttribute('href');
                        if (twitterLink) {
                            const twitter_link_array = twitterLink.split('/');
                            const length = twitter_link_array?.length;
                            const twitter_id = twitter_link_array[length - 1];
                            if (twitter_id !== undefined) {
                                // Create a blockquote for embedding the tweet
                                const embeded_twitter_html = `
                                <blockquote class="twitter-tweet">
                                    <a href="${twitterLink}"></a>
                                </blockquote>
                            `;
                                const divele = document.createElement('div');
                                divele.innerHTML = embeded_twitter_html;
                                eachImage.appendChild(divele);

                                // Load Twitter's embed script
                                if (!document.querySelector('script#twitter-wjs')) {
                                    const script = document.createElement('script');
                                    script.id = 'twitter-wjs';
                                    script.src = "https://platform.twitter.com/widgets.js";
                                    script.onload = () => {
                                        (window as any).twttr.widgets.load();
                                    };
                                    document.body.appendChild(script);
                                } else {
                                    // If script already exists, reload widgets after a short delay
                                    setTimeout(() => {
                                        if ((window as any).twttr && (window as any).twttr.widgets) {
                                            (window as any).twttr.widgets.load();
                                        }
                                    }, 500);
                                }

                                const disp_quote = eachImage.querySelectorAll('[class="disp-quote"]');
                                disp_quote.forEach(each_quote => {
                                    each_quote.classList.add('hide');
                                })
                            }
                            // eachImage.classList.remove('fig');
                        }
                    });
                }

                //for loading youtube videos
                const youtubeLinks = eachImage.querySelectorAll('[attr-ext-link-type="youtubeUrl"]');
                youtubeLinks.forEach(eachYouTube => {
                    const vimeoLink = eachYouTube?.getAttribute('href');
                    if (vimeoLink) {
                        const vimeo_link_array = vimeoLink.split('/');
                        const vimeo_id = vimeo_link_array['4'];

                        if (vimeo_id != undefined) {
                            const embeded_vimeo_html = '<iframe class="iframeclass" loading="lazy" title="About Us Template" src="' + vimeoLink + '" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" data-ratio="0.562" data-width="750" data-height="450"></iframe>';
                            const divele = document.createElement('div');
                            divele?.classList.add('vimeo_widget');
                            divele.innerHTML = embeded_vimeo_html;
                            // eachVimeo.innerHTML = embeded_vimeo_html;
                            eachYouTube.appendChild(divele);
                        }
                        eachImage.classList.remove('fig');
                        eachImage.classList.add('link');
                    }
                });

            });


            //to show bullet
            const bullet_tag = html.querySelectorAll("[attr-list-type='bullet']");
            bullet_tag.forEach(each_bullet => {
                const ul = document.createElement('ul');
                ul.setAttribute('id', each_bullet.id);
                ul.setAttribute('attr-list-type', 'bullet');
                ul.className = 'list';

                // Find all list-item divs and convert them to <li>
                const listItems = each_bullet.querySelectorAll('.list-item');
                listItems.forEach(listItem => {
                    const li = document.createElement('li');
                    li.className = 'list-item';

                    // Move the content from the list-item to the <li>
                    while (listItem.firstChild) {
                        li.appendChild(listItem.firstChild);
                    }

                    ul.appendChild(li);
                });

                // Replace the old list div with the new <ul>
                each_bullet.replaceWith(ul);
            });



            // to restructure reference tag
            const back_tag = html.querySelector("[class='ref-list']");
            // Find the span element within each_tag
            const ref_title = back_tag?.querySelector('div.title') as HTMLElement;
            ref_title?.classList.add('ref-title');

            // Set the inner HTML content
            this.referencesHTML = back_tag?.innerHTML;

            // Get all reference items with class 'p'
            // const refItems = back_tag?.querySelectorAll('div.ref') as NodeListOf<HTMLElement>;            

            // // // Create an <ol> element for ordered list
            // const olElement = document.createElement('ol');

            // // // Loop through each 'div.p' and convert it to 'li'
            // refItems?.forEach((refItem) => {
            //     // if (refItem.textContent == '') {
            //     //     refItem.remove();
            //     // }
            //     // else {
            //         const liElement = document.createElement('li');
            //         liElement.innerHTML = refItem.innerHTML; // Copy text content
            //         olElement.appendChild(liElement); // Add each 'li' to 'ol'
            //         refItem.remove(); // Remove original 'div.p' element
            //     // }
            // });

            //  // Append the new ordered list to 'back_tag'
            //  back_tag?.appendChild(olElement);

            //span break tags
            const break_tag = html.querySelectorAll("[attr-specific-use='break']")
            // Replace each with a <br> tag
            break_tag.forEach(each_break_tag => {
                const br = document.createElement('br');
                each_break_tag.parentNode?.replaceChild(br, each_break_tag);
            });

            //find ref link from ref tag
            const ref_link = back_tag?.querySelectorAll('a.ext-link');
            ref_link?.forEach(eachLink => {
                eachLink.classList.add('ref-link');
                const span_ref_link = eachLink.querySelector('span.bold');
                span_ref_link?.classList.remove('bold');
                span_ref_link?.classList.remove('section-title');
            })

            //to remove section-title
            const span_tag = back_tag?.querySelectorAll('span.section-title');
            span_tag?.forEach(eachSpan => {
                eachSpan?.classList.remove('section-title');
            })

            // Ensure all external links open in new tab
            const externalLinks = html.querySelectorAll('a.ext-link');
            if (externalLinks.length > 0) {
                externalLinks.forEach(link => {
                    link.setAttribute('target', '_blank');
                });
            }


            const serializer = new XMLSerializer();
            this.html_new_content = serializer.serializeToString(html);
            this.html_new_content_dupe = this.sanitizer.bypassSecurityTrustHtml(this.html_new_content);
            this.articleContetLoading = false;

        }
    }

    //to show tooltip in article contents section
    showTooltip(event: Event) {
        const aTagEle = event.target as HTMLInputElement;
        const refAttrVal = aTagEle?.getAttribute('attr-ref-type') as string;
        // if (aTagEle.className == 'xref' && refAttrVal == 'bibr') {
        //     this.tooltipStatus = 'index';
        //     const rid = aTagEle?.getAttribute('rid') as string;
        //     const referenceEle = document.querySelector(
        //         "[class='ref'][id='" + rid + "']"
        //     );
        //     if (referenceEle) {
        //         const refDetail = referenceEle.querySelector(
        //             "[class='mixed-citation']"
        //         );
        //         // aTagEle.setAttribute('class', 'tooltips');
        //         aTagEle.classList.add('tooltips');
        //         let detail = '';
        //         detail = "<span class='ref-pop-heading'>Reference</span>";
        //         detail =
        //             detail +
        //             "<div class='ref-tooltip'>" +
        //             refDetail?.innerHTML +
        //             '</div>';
        //         detail =
        //             aTagEle.innerText +
        //             "<span class='tooltiptext1'>" +
        //             detail +
        //             '</span>';
        //         aTagEle.innerHTML = detail;
        //     }
        // }
        if (aTagEle.className == 'xref' && refAttrVal == 'fn') {//for footnotes
            this.fn_group = true;
            this.tooltipStatus = 'index';
            const fid = aTagEle?.getAttribute('rid') as string;
            const referenceEle = document.querySelector(
                "[class='ref'][id='" + fid + "']"
            );
            if (referenceEle) {
                const footnotes = referenceEle.querySelector("[class='mixed-citation']");

                aTagEle.classList.add('tooltips');
                aTagEle.setAttribute('data-placement', 'bottom');
                let detail = '';
                let refdetaildata = ''
                if (footnotes) {
                    refdetaildata =
                        refdetaildata +
                        "<p attr-ref-type='bibr' rid='" + fid + "'>" + footnotes?.innerHTML + "</p>";
                    detail += "<div class='ref-tooltip'>" + refdetaildata + '</div>';
                }

                // footnotes.forEach(each_fn_p => {
                //     const refAttr = each_fn_p?.querySelectorAll("[class='xref']");
                //     let refdetaildata = ''
                //     refAttr?.forEach(ele => {
                //         const rid = ele?.getAttribute('rid') as string;
                //         const reference_rid_ele = document.querySelector(
                //             "[class='ref'][id='" + rid + "']"
                //         );
                //         if (reference_rid_ele) {
                //             const refDetail = reference_rid_ele.querySelector(
                //                 "[class='mixed-citation']"
                //             );
                //             refdetaildata =
                //                 refdetaildata +
                //                 // "<div class='ref'>" +
                //                 // fnHeading?.innerHTML +
                //                 "<p attr-ref-type='bibr' rid='" + rid + "'>" + refDetail?.innerHTML + "</p>";
                //             //         '</div>';
                //         }

                //         ele.removeAttribute('attr-ref-type');
                //     })
                //     detail += "<span class='ref-pop-heading'>" + each_fn_p?.innerHTML + "</span>" + "<div class='ref-tooltip'>" + refdetaildata + '</div>';
                // });


                // const refAttr = fnHeading?.querySelectorAll("[class='xref']");
                // refAttr?.forEach(ele => {
                //     const rid = ele?.getAttribute('rid') as string;
                //     const reference_rid_ele = document.querySelector(
                //         "[class='ref'][id='" + rid + "']"
                //     );
                //     if (reference_rid_ele) {
                //         const refDetail = reference_rid_ele.querySelector(
                //             "[class='mixed-citation']"
                //         );
                //         detail =
                //             detail +
                //             // "<div class='ref'>" +
                //             // fnHeading?.innerHTML +
                //             "<p attr-ref-type='bibr' rid='" + rid + "'>" + refDetail?.innerHTML + "</p>";
                //         //         '</div>';
                //     }
                //     ele.removeAttribute('attr-ref-type');
                // })
                // detail = "<span class='ref-pop-heading'>" + fnHeading?.innerHTML + "</span>" + "<div class='ref-tooltip'>" + detail + '</div>';
                const spanElement = document.createElement('span');
                spanElement.classList.add('tooltiptext1');
                spanElement.setAttribute('style', 'margin-top: -10px;');
                spanElement.innerHTML = detail;
                aTagEle.appendChild(spanElement);
            }
        }


        if (aTagEle.className == 'xref' && refAttrVal == 'other') {//for footnotes
            this.fn_group = true;
            this.tooltipStatus = 'index';
            const gid = aTagEle?.getAttribute('rid') as string;
            const glosserTag = document.querySelector("[class='glossary']");
            const glossaryEle = glosserTag.querySelectorAll(
                "[class='def-item']"
            );

            let detail = '';
            if (glossaryEle.length > 0) {
                glossaryEle.forEach(ele => {
                    if (ele.querySelector("[class='term'][id='" + gid + "']")) {
                        let ptag = ele.querySelector('.def');
                        if (ptag) {
                            aTagEle.classList.add('tooltips');
                            aTagEle.setAttribute('data-placement', 'bottom');
                            let refdetaildata = ''
                            refdetaildata =
                                refdetaildata +
                                "<p attr-ref-type='bibr' rid='" + gid + "'>" + ptag?.innerHTML + "</p>";
                            detail += "<div class='ref-tooltip'>" + refdetaildata + '</div>';
                        }
                    }
                })
                const spanElement = document.createElement('span');
                spanElement.classList.add('tooltiptext1');
                spanElement.setAttribute('style', 'margin-top: -10px;');
                spanElement.innerHTML = detail;
                aTagEle.appendChild(spanElement);
            }
        }

        // if (aTagEle.className == 'xref' && refAttrVal == 'supplementary-material') {
        //     this.tooltipStatus = 'index';
        //     const fid = aTagEle?.getAttribute('rid') as string;
        //     const referenceEle = document.querySelector(
        //         "[class='supplementary-material'][id='" + fid + "']"
        //     );
        //     if (referenceEle) {
        //         const fnHeading = referenceEle.querySelector("[class='caption']")
        //         aTagEle.classList.add('tooltips');
        //         aTagEle.setAttribute('data-placement', 'bottom');
        //         let detail = '';
        //         const fnContent = referenceEle.querySelector("[class='object-id']")

        //         // const refAttr = fnHeading?.querySelectorAll("[class='xref']");
        //         // refAttr?.forEach(ele => {
        //         //     const rid = ele?.getAttribute('rid') as string;
        //         //     const reference_rid_ele = document.querySelector(
        //         //         "[class='ref'][id='" + rid + "']"
        //         //     );
        //         //     if (reference_rid_ele) {
        //         //         const refDetail = reference_rid_ele.querySelector(
        //         //             "[class='mixed-citation']"
        //         //         );
        //         detail =
        //             detail +
        //             // "<div class='ref'>" +
        //             // fnHeading?.innerHTML +
        //             "<div>" + "<a class='doi-underline' href='" + fnContent?.innerHTML + "' target='_blank'>" + fnContent?.innerHTML + "</a>" + "</div>";
        //         //         '</div>';
        //         //     }
        //         //     ele.removeAttribute('attr-ref-type');
        //         // })
        //         detail = "<span class='ref-pop-heading'>" + fnHeading?.innerHTML + "</span>" + "<div class='ref-tooltip'>" + detail + '</div>';
        //         // detail =  "<div class='ref'>" + detail +'</div>';
        //         const spanElement = document.createElement('span');
        //         spanElement.classList.add('tooltiptext1');
        //         spanElement.setAttribute('style', 'margin-top: -10px;');
        //         spanElement.innerHTML = detail;
        //         aTagEle.appendChild(spanElement);
        //     }
        // }
    }

    ngOnDestroy() {
        this.subscriptions.forEach(x => {
            if (!x.closed) {
                x.unsubscribe();
            }
        });
    }

}