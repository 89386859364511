<div class="contributors-container">
    <div class="contributors-section">
        <h1>Contributors</h1>
        <div class="tabs">
            <button class="tab" [ngClass]="{'active': contributorData}"
                (click)="contributorData = true; expertData = false;">
                Contributing Authors
            </button>

            <button class="tab" [ngClass]="{'active': expertData}"
                (click)="expertData = true; contributorData = false;">
                Expert Reviewers
            </button>
        </div>
    </div>
    <div class="contributors-grid" *ngIf="!ContributorLoading; else NotContributorLoaded">
        <ng-container *ngIf="contributorData">
            <div class="contributor" *ngFor="let contributor of contributors">
                <h3 [innerHTML]="getAuthorlist(contributor._id)"> </h3>
                <ul *ngFor="let articles of contributor['articles']">
                    <li>
                        <a class="article_link"
                            [routerLink]="['/', articles.heading,articles.part,articles.article_link]">
                            {{articles.article_title}}
                        </a>
                    </li>
                </ul>
            </div>
        </ng-container>

        <ng-container *ngIf="expertData">
            <div class="contributor" *ngFor="let expert of expertReview">
                <h3 [innerHTML]="getAuthorlist(expert)"> </h3>
            </div>
        </ng-container>

    </div>
    <ng-template #NotContributorLoaded>
        <div class="contributors-grid">
            <div class="row">
                <div class="col-xl-4 pb-4" *ngFor="let eachArray of loadingArray">
                    <div class="contributor">
                        <h3 class="skeleton skeleton-heading skeleton-text"> </h3>
                        <ul>
                            <li>
                                <p class="article_link skeleton skeleton-text skeleton-text-80">

                                </p>
                            </li>
                            <li>
                                <p class="article_link skeleton skeleton-text skeleton-Al-3">

                                </p>
                            </li>
                        </ul>
                    </div>


                </div>
                
            
            </div>

        </div>
    </ng-template>
</div>