import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ShareButton } from 'ngx-sharebuttons/button';

import { Component, ElementRef, Inject, OnDestroy, OnInit, PLATFORM_ID, Renderer2, ViewChild, AfterViewInit, HostListener, ChangeDetectorRef } from '@angular/core';

import { Subscription } from 'rxjs';
import { env } from '../../config/config';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { NgClass, NgFor, NgIf, NgStyle, isPlatformBrowser } from '@angular/common';
import { SharedService } from '../../services/shared.service';
import { ArticleService } from '../../services/article.service';
import { articleOutline, articleView, authors_data, imagePaths } from '../../interfaces/article';
import { ChapterModalFigComponent } from '../chapter-modal-fig/chapter-modal-fig.component';


@Component({
    standalone: true,
    selector: 'app-chapter-view-page',
    imports: [ShareButton, FontAwesomeModule, NgIf, NgFor, NgStyle, NgClass, ChapterModalFigComponent],
    templateUrl: './chapter-view-page.component.html',
    styleUrl: './chapter-view-page.component.scss'
})
export class ChapterViewPageComponent {
    @ViewChild('contentLoaded') contentLoaded!: ElementRef<HTMLDivElement>;
    @ViewChild('introduction', { static: false }) introductionSection!: ElementRef;
    @ViewChild('modeOfAction', { static: false }) modeOfActionSection!: ElementRef;
    @ViewChild('dapsone', { static: false }) dapsoneSection!: ElementRef;
    @ViewChild('references', { static: false }) referencesSection!: ElementRef;
    @ViewChild('articlecontent') articleContent!: ElementRef<HTMLDivElement>;
    @ViewChild('appbottom') appbottom!: ElementRef<HTMLDivElement>;
    @ViewChild('articleInfo', { static: false }) articleInfo!: ElementRef;
    @ViewChild('myButton') myButton!: ElementRef;
    @ViewChild('myDiv') myDiv!: ElementRef;
    @ViewChild('tabContentDiv', { static: false }) tabContentDiv!: ElementRef<HTMLDivElement>;
    sharedUrl = '';
    journal_id = '';
    subscriptions: Subscription[] = [];
    articleView: articleView;
    articleCoverImageUrl = '';
    surgical_content: any[] = [];
    category = '';
    article_link = '';
    parser!: DOMParser;
    html_new_content = '';
    authorImages: string[] = [];
    html_new_content_dupe: any;
    relatedArticle: any[] = [];
    download_url: string = '';
    download_pdf = '';
    activeViewFont = "normal";
    downloadText = '';
    categorydocheadloading = true;
    pageLoading = true;
    articleTitleLoading = true;
    coverImgLoading = true;
    authorViewLoading = true;
    articleDoiLoading = true;
    articleContetLoading = true;
    relatedArticleLoading = true;
    authorsLoaded = true;
    citaionLoaded = true;
    contentLoadingArray = new Array(8).fill(0);
    articleFilePath: string;
    heading: string;
    caption: string;
    body: string;
    isModalOpen = false;
    div_data: Element;
    activeSection: string = '';
    activeTab = 'tab1';
    chapter_info = false;
    imagePaths: imagePaths[] = [];
    articleOutline: articleOutline[] = [];
    tooltipStatus = '';
    fn_group = false;
    isFixed = false;
    containerWidth: number = 0;
    referencesHTML: string | undefined;
    activeOutlineId: string | null = null;
    activeline: boolean[] = [];
    sectionOulineIsClikedOrNot = false;
    scrollEndTimeout: any;
    isTabActive = false;
    chapter_pdf = '';
    isCopied = false;
    topValue = '';
    part_id = '';

    constructor(

        private route: ActivatedRoute,
        private sanitizer: DomSanitizer,
        private renderer: Renderer2,
        private router: Router,
        private sharedService: SharedService,
        private articleService: ArticleService,
        private cdr: ChangeDetectorRef,
        @Inject(PLATFORM_ID) private platformId: any,
        private meta: Meta,
        private title: Title
    ) {
        if (isPlatformBrowser(this.platformId)) {
            this.parser = new DOMParser();

        }

    }

    ngOnInit() {
        const routesub = this.route.params.subscribe(() => {
            this.articleLoading();
        });
        this.subscriptions.push(routesub);
        if (this.articleView == undefined) {
            this.title.setTitle('International Textbook of Leprosy')
        }


    }
    @HostListener('window:resize', ['$event'])
    onResize(event: Event) {
        this.onScroll(event); // Pass the event to onScroll
        this.isTabActive = false;


    }
    @HostListener('window:scroll', ['$event'])
    onWindowScroll() {
        const scrollPosition =
            window.pageYOffset ||
            document.documentElement.scrollTop ||
            document.body.scrollTop ||
            0;
        clearTimeout(this.scrollEndTimeout);
        if (!this.sectionOulineIsClikedOrNot) {

            const sections = document.querySelectorAll('.sec[secid]') as NodeListOf<HTMLElement>;

            sections.forEach((section: HTMLElement) => {
                if (section) {
                    const secId: number = Number(section.getAttribute('secid'));
                    const sectionTop = section.offsetTop;
                    const sectionBottom = sectionTop + section.offsetHeight;
                    if (sectionTop <= scrollPosition + window.innerHeight && sectionBottom >= scrollPosition) {
                        this.setActiveLine(secId - 1);
                    }
                }
            });

        }
        this.scrollEndTimeout = setTimeout(() => {
            // Scroll end logic
            if (this.sectionOulineIsClikedOrNot === true) this.sectionOulineIsClikedOrNot = false;
        }, 200);


    }

    /**
     * Set the active section in the timeline
     * @param secId The ID of the section to be marked as active
     */
    setActiveLine(secId: number) {
        Object.keys(this.activeline).forEach((key: any) => this.activeline[key] = false);
        this.activeline[secId] = true;

    }
    /**
    * function to get article view data
    */
    articleLoading() {
        this.journal_id = "ITL"; //this.sharedService.journalId; 
        // if (this.route.snapshot.params['category']) {
        //     this.category = this.route.snapshot.params['category'];
        // }
        // else {
        //     this.category = this.route.snapshot.params['subcategory'];

        // }
        this.category = this.route.snapshot.params['heading_id'];
        this.part_id = this.route.snapshot.params['part_id'];
        this.article_link = this.route.snapshot.params['article-link'];

        const queryParams = {
            journal_id: this.journal_id,
            category: this.category,
            part:this.part_id,
            article_link: this.article_link,
            preview_mode: ''
        };
        const viewSub = this.articleService.getArticleViewData(queryParams).subscribe(result => {
            if (result?.data?.length === 0) {
                // Redirect to the page not found route
                this.router.navigate(['/page-not-found']);
            }
            this.articleView = result.data;
            if (this.articleView !== undefined) {
                this.meta.updateTag({ property: 'og:url', content: `${env.siteUrl}/chapter/${this.articleView?.article_title}` });
                this.meta.updateTag({ property: 'og:title', content: `${this.modifyTitle(this.articleView?.article_title)} | International Textbook of Leprosy` });
                this.meta.updateTag({ property: 'og:type', content: 'article' });

                this.title.setTitle(`${this.modifyTitle(this.articleView?.article_title)} |  International Textbook of Leprosy}`);
            }

            this.authorViewLoading = false;
            this.articleFilePath = this.articleView.art_file_path;
            this.chapter_pdf = env.articleImage + this.articleFilePath + '/' + this.articleView?.article_id + '.pdf';

            // this.storeAuthorImage();
            // this.joinSurgicalAndContent();
            this.modifyingHtmlContent(this.articleView?.html_content);

            this.categorydocheadloading = false;
            this.pageLoading = false;
            this.articleTitleLoading = false;
            this.articleDoiLoading = false;
            this.authorsLoaded = false;
            this.citaionLoaded = false;
            this.setCustomFontSize();


        });
        this.subscriptions.push(viewSub);        

        this.sharedUrl = env.siteUrl + this.category + '/' + this.part_id + '/' + this.article_link;
    }

    modifyTitle(title: string) {
        title = title
            .replace(/&lt;/g, '<')
            .replace(/&gt;/g, '>')
            .replace(/&amp;/g, '&');
        return title;
    }

    openDyslexic(mode: string) {
        this.activeViewFont = mode;
        if (mode == 'dyslexic') {
            this.contentLoaded.nativeElement.classList.add('dyslexic-regular');
            this.appbottom.nativeElement?.classList.add('dyslexic-regular');
        }
        else if (mode == 'normal') {
            this.contentLoaded.nativeElement.classList.remove('dyslexic-regular');
            this.appbottom.nativeElement.classList.remove('dyslexic-regular');
        }

    }

    openDownloadLink() {
        window.open(this.download_url, '_blank');
    }

    setCustomFontSize() {
        if (isPlatformBrowser(this.platformId)) {
            document.querySelectorAll(`[attr-specific-use^="font-"]`).forEach(function (item) {
                const element = item as HTMLElement;

                const widthValue = item.getAttribute('attr-specific-use').replace('font-', '');
                // Apply the extracted width to the element
                element.style.fontSize = `${widthValue}px`;
            });
        }
    }

    setCustomStyle() {
        document.querySelectorAll(`[attr-style]`).forEach(function (item) {
            const element = item as HTMLElement;

            const widthValue = item.getAttribute('attr-style').replace('font-', '');
            // Apply the extracted width to the element
            element.style.fontSize = `${widthValue}px`;
        });
    }

    //to display modal and to navigate to reference
    refClick(event: Event) {
        this.showTooltip(event);
        const aTagEle = event.target as HTMLInputElement;
        const refAttrVal = aTagEle?.getAttribute("attr-ref-type") as string;

        if (refAttrVal == 'fn') {
            const ridValue = aTagEle.getAttribute('rid');
            const referenceEle: Element | null = document.querySelector(
                "[class='ref'][id='" + ridValue + "']"
            );
            // referenceEle?.classList.add("blinking")
            // setTimeout(() => {
            //     // Code to be executed after a delay
            //     referenceEle?.classList.remove("blinking")
            // }, 2500);
            setTimeout(() => {
                this.showTooltip(event);
            }, 50);
            setTimeout(() => {
                this.scrollToElement(referenceEle);
            }, 1000);
        }


        if (aTagEle.classList.contains('graphic')) {  // Check if the clicked element has the 'graphic' class
            const href: string = aTagEle.getAttribute('href') as string;
            const result = href.substring(0, href.indexOf('.')); // Extract the content before the first period

            const figElements = document.querySelectorAll(".fig"); // Query all elements with class 'fig'

            if (figElements.length > 0) {
                figElements.forEach((eachFig) => {
                    const imgElement = eachFig.querySelector('img.graphic');
                    if (imgElement) {
                        const imgHref = imgElement.getAttribute('href');
                        if (imgHref && imgHref.includes(result)) {
                            this.div_data = eachFig; // Store the reference to the matched fig element
                        }
                    }
                });

            }
            else {
                this.div_data = aTagEle;
            }


            if (this.div_data) {
                // Clone the div_data to ensure the original remains in the DOM
                this.div_data = this.div_data.cloneNode(true) as HTMLElement;
                this.isModalOpen = true;
            }
        }

        if (aTagEle.classList.contains('img-modal')) {
            const aTagEle = event.target as HTMLInputElement;
            this.div_data = aTagEle;
            if (this.div_data) {
                // Clone the div_data to ensure the original remains in the DOM
                this.div_data = this.div_data.cloneNode(true) as HTMLElement;
                this.isModalOpen = true;
            }
        }
    }

    //to scroll to particular element's position
    scrollToElement(pageElement: any) {
        if (pageElement) {
            const yOffset = -150; // Adjust this offset as needed
            const y = pageElement.getBoundingClientRect().top + window.pageYOffset + yOffset;
            // var ele = pageElement.querySelector("[class='title']");
            pageElement.classList.add('highlightAlph');

            setTimeout(() => {
                pageElement.classList.remove('highlightAlph');
            }, 2000);

            window.scrollTo({ top: y, behavior: 'smooth' });
        }

    }


    loadModal(event: Event) {

        const aTagEle = event.target as HTMLInputElement;
        this.div_data = aTagEle;
        if (this.div_data) {
            // Clone the div_data to ensure the original remains in the DOM
            this.div_data = this.div_data.cloneNode(true) as HTMLElement;
            this.isModalOpen = true;
        }
    }


    closeJoinModal() {
        this.isModalOpen = false;
    }

    showContent(section: string) {
        this.activeSection = section;
    }

    setActiveTab(tab: string) {
        this.activeTab = tab;
    }

    scrollToSection(i: number, secId: string) {
        this.sectionOulineIsClikedOrNot = true;
        this.activeOutlineId = secId;
        this.activeline = [];
        this.activeline[i] = true;
        const sectionElement = document.querySelector(`[secid="${secId}"]`);
        if (sectionElement) {
            const yOffset = -150; // Adjust this offset as needed
            const y = sectionElement.getBoundingClientRect().top + window.pageYOffset + yOffset;
            var ele = sectionElement.querySelector("[class='title']");
            ele.classList.add('highlightAlph');

            // console.log(sectionElement.querySelector("class='title'"));
            setTimeout(() => {
                ele.classList.remove('highlightAlph');
            }, 2000);

            window.scrollTo({ top: y, behavior: 'smooth' });
        } else {
            console.warn('Section not found with secId:', secId);
        }
    }

    downloadPDF(): void {
        window.open('https://www.internationaltextbookofleprosy.org/sites/default/files/ITL_5_2%20FINAL.pdf', '_blank');
    }

    onScroll(event: any) {

        const scrollPosition = window.scrollY;
        const targetElement = document.getElementById('citation');
        const containerElement = targetElement?.parentElement;
        const endContentElement = document.getElementById('article-content'); // The right column to detect when scrolling past it
        if (this.articleContent?.nativeElement) {
            const targetPosition = targetElement.offsetTop;
            const endPosition = this.articleContent?.nativeElement.offsetHeight;
            this.containerWidth = targetElement.offsetWidth;
            // Check if the user has scrolled past the container but not beyond the main content
            if (scrollPosition >= targetPosition) {
                this.isFixed = true; // Apply fixed position
                this.renderer.setStyle(this.tabContentDiv.nativeElement, 'height', '73%');
                // const buttonRect = this.myButton.nativeElement.getBoundingClientRect();
                // this.myDiv.nativeElement.style.top = `${buttonRect.top - 20}px`;

            } else {
                this.isFixed = false; // Remove fixed position
                this.renderer.setStyle(this.tabContentDiv.nativeElement, 'height', '35%');
                const buttonRect = this.myButton.nativeElement.getBoundingClientRect();
                this.myDiv.nativeElement.style.top = `${buttonRect.top - 20}px`;
            }

        }
    }

    returnAuthor(authors: authors_data[]) {
        let name: string[] = [];
        authors?.forEach(each_auth => {
            const eachname = each_auth.given_name + ' ' + each_auth.surname;
            name.push(eachname);
        })
        return name.join(', ');

    }

    modifyingHtmlContent(html_content: string) {
        if (isPlatformBrowser(this.platformId)) {
            if (html_content) {
                const html = this.parser?.parseFromString(html_content, 'text/html');

                //recreating html............

                // to hide front tag
                const content = html?.querySelector("[class='front']");

                content?.classList.add('hide');

                //=========== to hide doi section ====================//
                //getting all p tag
                const p_tags = html?.querySelectorAll("[class='p']");
                const regex = /https?:\/\/doi\.org\/(\d+\.\d+)/;
                if (this.articleView?.doi) {
                    const match = this.articleView?.doi.match(regex);
                    // let extractedDOI='';
                    if (match) {
                        var extractedDOI = `doi.org/${match[1]}`;
                    }
                }

                const sec_tags = html?.querySelectorAll('.sec');
                let sec_id = 0;
                sec_tags?.forEach(each_sec => {
                    // Check if the parent of this 'sec' is not another 'sec'
                    if (!each_sec.closest('.sec > .sec')) {
                        sec_id++;
                        each_sec.setAttribute('secId', sec_id.toString());

                        let title = each_sec.querySelector('.title').textContent

                        const outline: articleOutline = {
                            title: title,
                            outlineId: sec_id.toString()
                        }
                        this.activeline = [];
                        this.activeline[0] = true;
                        this.articleOutline.push(outline);
                    }
                });




                //hiding doi tag
                p_tags?.forEach(each_tag => {
                    if (each_tag.innerHTML.includes(extractedDOI)) {
                        each_tag.classList.add("hide"); // add the 'hidden' class
                    }

                    // Find the span element within each_tag
                    // const spanElement = each_tag.querySelector('span.bold') as HTMLElement;
                    // if (spanElement) {
                    //     spanElement.classList.add('section-title');
                    //     // Perform additional actions if needed
                    // }

                    // if(each_tag.attr)
                    if (each_tag.attributes[1]?.nodeValue == 'button-read-more' && each_tag.hasChildNodes()) {
                        each_tag.childNodes.forEach((node: any) => {
                            // if (node.textContent == 'Download PDF') {
                            this.downloadText = node?.textContent;
                            this.download_url = node?.href;
                            const download_array = this.download_url.split('/');
                            if (download_array.length > 0) {
                                const length = download_array?.length;
                                this.download_pdf = download_array[length - 1];
                                this.download_url = env.articleImage + this.articleFilePath + '/' + this.download_pdf;
                            }
                            each_tag.setAttribute('style', 'display: none;');
                            // }
                        })
                        each_tag.classList.add('read-more');
                    }

                });



                //getting fig group
                const fig_group_tags = html?.querySelectorAll("[class='fig']");
                fig_group_tags?.forEach(fig_each_tag => {
                    const fig_img_tag = fig_each_tag.querySelectorAll('img.graphic');
                    fig_img_tag?.forEach(img => {
                        const imgSrc = img.attributes[1]?.nodeValue; // Assuming 'src' is the attribute to match
                        if (imgSrc && this.authorImages.includes(imgSrc)) {
                            fig_each_tag.classList.add("hide");
                        }
                    });
                })

                //restructuring images
                this.imagePaths = [];
                const fig_tags = Array.from(html?.querySelectorAll("div.fig") || []) // Ensure it returns an array
                    .filter(fig => !fig.classList.contains('hide'));
                Array.from(fig_tags)?.forEach((eachImage) => {
                    const img = eachImage?.querySelector('img') as HTMLElement;
                    if (img) {
                        const caption = eachImage.querySelector("div.caption") as HTMLElement;
                        caption?.classList.remove('caption');
                        caption?.classList.add('fig-caption');
                        const fig_span = eachImage.querySelector("span.bold") as HTMLElement;
                        fig_span?.classList.remove('section-title');
                        fig_span?.classList.add('figure');
                        if (caption == null) {
                            eachImage.classList.remove('fig');
                            img.classList.add('small-image');
                        }
                        else {
                            img.setAttribute('width', '100%');
                            img.setAttribute('height', '100%');
                        }
                        const srcValue = img?.getAttribute('href') as string;
                        img.setAttribute(
                            'src',
                            env.apiUrl + 'storage/' + this.articleView.art_file_path +
                            '/' +
                            srcValue
                        );
                        const data: imagePaths = {
                            srcValue: srcValue,
                            imageUrl:
                                env.apiUrl + 'storage/' + this.articleView.art_file_path +
                                '/' +
                                srcValue
                        };

                        this.imagePaths.push(data);

                    }

                    //for loading vimeo widgets Url
                    const url = eachImage.querySelectorAll('[attr-ext-link-type="Url"]');
                    if (url.length > 0) {
                        url.forEach(eachUrl => {
                            const vimeoLink = eachUrl?.getAttribute('href');
                            if (vimeoLink) {
                                const vimeo_link_array = vimeoLink.split('/');
                                const vimeo_id = vimeo_link_array['4'];

                                if (vimeo_id != undefined) {
                                    const embeded_vimeo_html = '<iframe class="exturlclass" loading="lazy" title="About Us Template" src="' + vimeoLink + '" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" data-ratio="0.562" data-width="750" data-height="450"></iframe>';
                                    const divele = document.createElement('div');
                                    divele?.classList.add('vimeo_widget');
                                    divele.innerHTML = embeded_vimeo_html;
                                    // eachVimeo.innerHTML = embeded_vimeo_html;
                                    eachUrl.appendChild(divele);
                                }
                                eachImage.classList.remove('fig');
                                eachImage.classList.add('link');
                            }

                        })
                    }

                    //for loading vimeo widgets
                    const vimeo = eachImage.querySelectorAll('[attr-ext-link-type="vimeoUrl"]');
                    if (vimeo.length > 0) {
                        vimeo.forEach(eachVimeo => {
                            const vimeoLink = eachVimeo?.getAttribute('href');
                            if (vimeoLink) {
                                const vimeo_link_array = vimeoLink.split('/');
                                const vimeo_id = vimeo_link_array['4'];

                                if (vimeo_id != undefined) {
                                    const embeded_vimeo_html = '<iframe class="iframeclass" loading="lazy" title="About Us Template" src="' + vimeoLink + '" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" data-ratio="0.562" data-width="750" data-height="450"></iframe>';
                                    const divele = document.createElement('div');
                                    divele?.classList.add('vimeo_widget');
                                    divele.innerHTML = embeded_vimeo_html;
                                    // eachVimeo.innerHTML = embeded_vimeo_html;
                                    eachVimeo.appendChild(divele);
                                }
                                eachImage.classList.remove('fig');
                                eachImage.classList.add('link');
                            }
                        });
                    }

                    //for loading twitter links
                    const twitter = eachImage.querySelectorAll('[attr-ext-link-type="twitterUrl"]');
                    if (twitter.length > 0) {
                        twitter.forEach(eachTwitter => {
                            let twitterLink: any = eachTwitter?.getAttribute('href');
                            if (twitterLink) {
                                const twitter_link_array = twitterLink.split('/');
                                const length = twitter_link_array?.length;
                                const twitter_id = twitter_link_array[length - 1];
                                if (twitter_id !== undefined) {
                                    // Create a blockquote for embedding the tweet
                                    const embeded_twitter_html = `
                                <blockquote class="twitter-tweet">
                                    <a href="${twitterLink}"></a>
                                </blockquote>
                            `;
                                    const divele = document.createElement('div');
                                    divele.innerHTML = embeded_twitter_html;
                                    eachImage.appendChild(divele);

                                    // Load Twitter's embed script
                                    if (!document.querySelector('script#twitter-wjs')) {
                                        const script = document.createElement('script');
                                        script.id = 'twitter-wjs';
                                        script.src = "https://platform.twitter.com/widgets.js";
                                        script.onload = () => {
                                            (window as any).twttr.widgets.load();
                                        };
                                        document.body.appendChild(script);
                                    } else {
                                        // If script already exists, reload widgets after a short delay
                                        setTimeout(() => {
                                            if ((window as any).twttr && (window as any).twttr.widgets) {
                                                (window as any).twttr.widgets.load();
                                            }
                                        }, 500);
                                    }

                                    const disp_quote = eachImage.querySelectorAll('[class="disp-quote"]');
                                    disp_quote.forEach(each_quote => {
                                        each_quote.classList.add('hide');
                                    })
                                }
                                // eachImage.classList.remove('fig');
                            }
                        });
                    }

                    //for loading youtube videos
                    const youtubeLinks = eachImage.querySelectorAll('[attr-ext-link-type="youtubeUrl"]');
                    youtubeLinks.forEach(eachYouTube => {
                        const vimeoLink = eachYouTube?.getAttribute('href');
                        if (vimeoLink) {
                            const vimeo_link_array = vimeoLink.split('/');
                            const vimeo_id = vimeo_link_array['4'];

                            if (vimeo_id != undefined) {
                                const embeded_vimeo_html = '<iframe class="iframeclass" loading="lazy" title="About Us Template" src="' + vimeoLink + '" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" data-ratio="0.562" data-width="750" data-height="450"></iframe>';
                                const divele = document.createElement('div');
                                divele?.classList.add('vimeo_widget');
                                divele.innerHTML = embeded_vimeo_html;
                                // eachVimeo.innerHTML = embeded_vimeo_html;
                                eachYouTube.appendChild(divele);
                            }
                            eachImage.classList.remove('fig');
                            eachImage.classList.add('link');
                        }
                    });

                });

                const thElements = html?.querySelectorAll('th[attr-style]');
                thElements?.forEach((th) => {
                    const attrStyle = th.getAttribute('attr-style');
                    if (attrStyle) {
                        th.setAttribute('style', attrStyle);
                        //   th.removeAttribute('attr-style'); // Remove incorrect attribute
                    }
                });

                // //to show bullet
                // const bullet_tag = html.querySelectorAll("[attr-list-type='bullet']");
                // bullet_tag.forEach(each_bullet => {
                //     const ul = document.createElement('ul');
                //     ul.setAttribute('id', each_bullet.id);
                //     ul.setAttribute('attr-list-type', 'bullet');
                //     ul.className = 'list';

                //     // Find all list-item divs and convert them to <li>
                //     const listItems = each_bullet.querySelectorAll('.list-item');
                //     listItems.forEach(listItem => {
                //         const li = document.createElement('li');
                //         li.className = 'list-item';

                //         // Move the content from the list-item to the <li>
                //         while (listItem.firstChild) {
                //             li.appendChild(listItem.firstChild);
                //         }

                //         ul.appendChild(li);
                //     });

                //     // Replace the old list div with the new <ul>
                //     each_bullet.replaceWith(ul);
                // });



                // to restructure reference tag
                const back_tag = html?.querySelector("[class='ref-list']");
                // Find the span element within each_tag
                const ref_title = back_tag?.querySelector('div.title') as HTMLElement;
                if (ref_title) {
                    ref_title.classList.add('ref-title');
                    ref_title.innerText = 'References';
                }

                // Set the inner HTML content for references
                this.referencesHTML = back_tag?.innerHTML;

                const glossary_tag = html?.querySelector("[class='glossary']");
                const def_list = glossary_tag?.querySelector("[class='def-list']");
                const footnote_tag = html?.querySelector("[class='ref-list']"); // Getting footnote tag
                const body_div = html?.querySelector(".body");//getting body tag
                const referenceMap = new Map<string, { count: number; labels: string[] }>();
                const labelSequence = Array?.from({ length: 26 }, (_, i) => String.fromCharCode(97 + i)); // ['a' to 'z']

                // Loop through glossary items and add links in the body (unchanged)
                def_list?.querySelectorAll("[class='def-item']").forEach(defItem => {
                    const termElement = defItem.querySelector('.term');
                    if (!termElement) return;

                    const gid = termElement.getAttribute('id');
                    const textContent = termElement.textContent || "";

                    const regex = new RegExp(`\\b${textContent}\\b`, "g");

                    body_div.innerHTML = body_div.innerHTML.replace(regex, `<a class="xref" attr-ref-type="other" rid="${gid}" id="${gid}">${textContent}</a>`);
                    footnote_tag.innerHTML = footnote_tag.innerHTML.replace(regex, `<a class="xref" attr-ref-type="other" rid="${gid}">${textContent}</a>`);
                });

                glossary_tag?.classList.add('hide');

                // **Track references and their labels based on occurrences in the body**
                const xrefElements = body_div?.querySelectorAll('a.xref');
                xrefElements?.forEach((xref) => {
                    const rid = xref.getAttribute('rid');
                    if (rid) {
                        // Track references based on their rid and their number of occurrences
                        if (!referenceMap.has(rid)) {
                            referenceMap.set(rid, { count: 0, labels: [] });
                        }

                        const refData = referenceMap.get(rid)!;
                        refData.count += 1; // Increment count for each occurrence

                        const label = labelSequence[refData.labels.length]; // Get label for current occurrence
                        refData.labels.push(label);
                    }
                });

                // **Only modify the reference section**
                footnote_tag?.querySelectorAll('.ref').forEach((refItem) => {
                    const rid = refItem.getAttribute('id');
                    if (rid) {
                        const refData = referenceMap.get(rid);
                        const referenceEle: Element | null = document.querySelector(
                            "[class='ref'][id='" + rid + "']"
                        );

                        if (refData) {
                            //  If only one label, show `^` instead of just `a`
                            const clickableLabels = refData.labels.length === 1
                                ? `<a href="#" class="xref cursor-pointer tooltips" data-rid="${rid}" attr-ref-type="fn" 
                              style="text-decoration: none;">^</a>`
                                : refData.labels.map(label =>
                                    `<a href="#" class="xref cursor-pointer tooltips" data-rid="${rid}" attr-ref-type="fn" 
                                style="text-decoration: none;">${label}</a>`
                                ).join(', ');



                            // Wrap in `<sup>` tag for superscript effect
                            refItem.innerHTML = `<sup>${clickableLabels}</sup> ` + refItem.innerHTML;
                            refItem.setAttribute('data-ref-labels', refData.labels.join(', '));
                        }
                    }
                });
                setTimeout(() => {
                    document.querySelectorAll('.xref.cursor-pointer').forEach((xref) => {
                        xref.addEventListener('click', (event) => {
                            event.preventDefault();

                            let clickedElement = event.target as HTMLElement;

                            // If clicking on <sup>, get the <a> inside it
                            if (clickedElement.tagName === 'SUP') {
                                clickedElement = clickedElement.querySelector('a.xref') as HTMLElement || clickedElement;
                            }

                            if (!clickedElement.classList.contains('xref')) return;

                            const rid = clickedElement.getAttribute('data-rid');

                            if (!rid) {
                                return;
                            }

                            // **Get all occurrences of this reference in the content**
                            const matchingReferences = Array.from(document.querySelectorAll(`a.xref[rid="${rid}"]`));

                            // **Find the clicked label among labels with the same `rid`**
                            const allMatchingLabels = Array.from(document.querySelectorAll(`.xref.cursor-pointer[data-rid="${rid}"]`));
                            const clickedIndex = allMatchingLabels.indexOf(clickedElement);


                            // **Ensure clickedIndex is within bounds**
                            if (clickedIndex < 0 || clickedIndex >= matchingReferences.length) {
                                return;
                            }

                            let targetElement = matchingReferences[clickedIndex] as HTMLElement;

                            if (!targetElement) {
                                return;
                            }


                            // **Apply highlight effect**
                            targetElement.style.backgroundColor = "#bbccd5";
                            targetElement.style.color = "black";

                            setTimeout(() => {
                                targetElement.style.backgroundColor = "";
                            }, 2000);

                            // **Ensure smooth scrolling to the correct reference**
                            targetElement.scrollIntoView({
                                behavior: 'smooth',
                                block: 'center',
                            });

                            // **Force tooltip to appear after scrolling**
                            setTimeout(() => {
                                this.showTooltip({ target: targetElement } as unknown as Event);
                            }, 500); // Small delay after scrolling
                        });
                    });
                }, 2000);





                //span break tags
                const break_tag = html?.querySelectorAll("[attr-specific-use='break']")
                // Replace each with a <br> tag
                break_tag?.forEach(each_break_tag => {
                    const br = document.createElement('br');
                    each_break_tag.parentNode?.replaceChild(br, each_break_tag);
                });

                //find ref link from ref tag
                const ref_link = back_tag?.querySelectorAll('a.ext-link');
                ref_link?.forEach(eachLink => {
                    eachLink.classList.add('ref-link');
                    const span_ref_link = eachLink.querySelector('span.bold');
                    span_ref_link?.classList.remove('bold');
                    span_ref_link?.classList.remove('section-title');
                })

                //to remove section-title
                const span_tag = back_tag?.querySelectorAll('span.section-title');
                span_tag?.forEach(eachSpan => {
                    eachSpan?.classList.remove('section-title');
                })

                // Ensure all external links open in new tab
                const externalLinks = html?.querySelectorAll('a.ext-link');
                if (externalLinks?.length > 0) {
                    externalLinks.forEach(link => {
                        link.setAttribute('target', '_blank');
                    });
                }

                const serializer = new XMLSerializer();
                this.html_new_content = serializer.serializeToString(html);
                this.html_new_content = this.html_new_content
                    .replace(/&lt;/g, '<')
                    .replace(/&gt;/g, '>')
                    .replace(/&amp;/g, '&');
                this.html_new_content_dupe = this.sanitizer.bypassSecurityTrustHtml(this.html_new_content);
                this.articleContetLoading = false;

            }
        }
    }

    // to show tooltip in article contents section
    // showTooltip(event: Event) {


    //     let aTagEle = event.target as HTMLElement;


    //     // If clicked element is <sup>, get <a> inside it
    //     if (aTagEle.tagName === 'SUP') {
    //         aTagEle = aTagEle.querySelector('a.xref') as HTMLElement || aTagEle;
    //     }
    //     if (!aTagEle.classList.contains('xref')) return;

    //     const refAttrVal = aTagEle?.getAttribute('attr-ref-type') as string;

    //     // Remove existing tooltips before adding a new one
    //     aTagEle.querySelectorAll('.tooltiptext1').forEach(el => el.remove());

    //     if (refAttrVal == 'fn') {
    //         this.fn_group = true;
    //         this.tooltipStatus = 'index';

    //         const fid = aTagEle?.getAttribute('rid') as string;
    //         const referenceEle = document.querySelector(`.ref[id='${fid}']`);

    //         if (referenceEle) {
    //             const footnotes = referenceEle.querySelector('.mixed-citation');
    //             // aTagEle.classList.add('tooltips','dyslexic-regular');

    //             aTagEle.classList.add('tooltips');
    //             aTagEle.setAttribute('data-placement', 'left');

    //             let refdetaildata = '';
    //             if (footnotes) {
    //                 refdetaildata = `<p attr-ref-type='bibr' rid='${fid}'>${footnotes.innerHTML}</p>`;
    //             }

    //             const spanElement = document.createElement('span');
    //             // spanElement.classList.add('tooltiptext1', 'dyslexic-regular'); // ✅ Apply dyslexic style

    //             spanElement.classList.add('tooltiptext1');
    //             spanElement.setAttribute('style', 'margin-top: 3px;');
    //             spanElement.innerHTML = `<div class='ref-tooltip'>${refdetaildata}</div>`;


    //             aTagEle.appendChild(spanElement);

    //             // ✅ **Remove Tooltip When Clicking Anywhere on the Page**
    //             const removeTooltip = (e: Event) => {
    //                 spanElement.remove();
    //                 document.removeEventListener('click', removeTooltip);
    //             };

    //             document.addEventListener('click', removeTooltip);

    //             // ✅ **Remove Tooltip When Mouse Leaves the Reference**
    //             aTagEle.addEventListener('mouseleave', () => {
    //                 spanElement.remove();
    //                 document.removeEventListener('click', removeTooltip);
    //             });
    //         }
    //     }
    //     if (refAttrVal == 'other') { // For glossary tooltips
    //         this.fn_group = true;
    //         this.tooltipStatus = 'index';

    //         const gid = aTagEle?.getAttribute('rid') as string;
    //         const glossaryTag = document.querySelector(".glossary.hide");
    //         const glossaryItems = glossaryTag?.querySelectorAll(".def-item");

    //         let detail = '';
    //         if (glossaryItems && glossaryItems.length > 0) {
    //             glossaryItems.forEach(ele => {
    //                 if (ele.querySelector(`.term[id='${gid}']`)) {
    //                     aTagEle.classList.add('tooltips');
    //                     aTagEle.setAttribute('data-placement', 'up');

    //                     let refdetaildata = `<p attr-ref-type='bibr' rid='${gid}'>${ele.innerHTML}</p>`;
    //                     detail += `<div class='ref-tooltip'>${refdetaildata}</div>`;
    //                 }
    //             });

    //             const spanElement = document.createElement('span');
    //             spanElement.classList.add('tooltiptext1');
    //             spanElement.setAttribute('style', 'margin-top: 3px;');
    //             spanElement.innerHTML = detail;

    //             aTagEle.appendChild(spanElement);

    //             // ✅ Ensure Tooltip is Removed and Re-Created on Every Hover
    //             aTagEle.addEventListener('mouseleave', () => {
    //                 spanElement.remove();
    //             });

    //             aTagEle.addEventListener('mouseenter', () => {
    //                 this.showTooltip(event); // Recreate tooltip on hover
    //             });
    //         }
    //     }
    // }
    showTooltip(event: Event) {
        let aTagEle = event.target as HTMLElement;

        // If clicked element is <sup>, get <a> inside it
        if (aTagEle.tagName === 'SUP') {
            aTagEle = aTagEle.querySelector('a.xref') as HTMLElement || aTagEle;
        }
        if (!aTagEle.classList.contains('xref')) return;

        const refAttrVal = aTagEle?.getAttribute('attr-ref-type') as string;

        // Remove existing tooltips before adding a new one
        aTagEle.querySelectorAll('.tooltiptext1').forEach(el => el.remove());

        if (refAttrVal === 'fn' || refAttrVal === 'other') {
            this.fn_group = true;
            this.tooltipStatus = 'index';

            let refdetaildata = '';
            if (refAttrVal === 'fn') {
                const fid = aTagEle?.getAttribute('rid') as string;
                const referenceEle = document.querySelector(`.ref[id='${fid}']`);
                if (referenceEle) {
                    const footnotes = referenceEle.querySelector('.mixed-citation');
                    if (footnotes) {
                        refdetaildata = `<p attr-ref-type='bibr' rid='${fid}'>${footnotes.innerHTML}</p>`;
                    }
                }
            } else if (refAttrVal === 'other') {
                this.fn_group = true;
                this.tooltipStatus = 'index';

                const gid = aTagEle?.getAttribute('rid') as string;
                const glossaryTag = document.querySelector(".glossary.hide");
                const glossaryItems = glossaryTag?.querySelectorAll(".def-item");

                let detail = '';
                if (glossaryItems && glossaryItems.length > 0) {
                    glossaryItems.forEach(ele => {
                        if (ele.querySelector(`.term[id='${gid}']`)) {
                            aTagEle.classList.add('tooltips');
                            aTagEle.setAttribute('data-placement', 'up');

                            let refdetaildata = `<p attr-ref-type='bibr' rid='${gid}'>${ele.innerHTML}</p>`;
                            detail += `<div class='ref-tooltip'>${refdetaildata}</div>`;
                        }
                    });

                    const spanElement = document.createElement('span');
                    spanElement.classList.add('tooltiptext1');
                    spanElement.setAttribute('style', 'margin-top: 3px;');
                    if (window.innerWidth <= 1200) {
                        spanElement.style.maxWidth = '250px';  // Mobile width
                        spanElement.style.fontSize = '14px';  // Mobile font size
                        spanElement.style.padding = '8px';    // Adjust padding for mobile
                        spanElement.style.position = 'absolute';
                        spanElement.style.left = '68%';
                        spanElement.style.transform = 'translateX(-50%)';
                    } else {
                        spanElement.style.maxWidth = '350px';  // Desktop width
                        spanElement.style.fontSize = '16px';
                        spanElement.style.position = 'absolute';
                        spanElement.style.left = 'auto';
                        spanElement.style.transform = 'none';
                    }
                    spanElement.innerHTML = detail;

                    aTagEle.appendChild(spanElement);

                    // ✅ Ensure Tooltip is Removed and Re-Created on Every Hover
                    aTagEle.addEventListener('mouseleave', () => {
                        spanElement.remove();
                    });

                    aTagEle.addEventListener('mouseenter', () => {
                        this.showTooltip(event); // Recreate tooltip on hover
                    });
                }

            }

            if (refdetaildata) {
                const spanElement = document.createElement('span');
                spanElement.classList.add('tooltiptext1');

                // Apply responsive styles, including background color, box-shadow, and z-index
                spanElement.style.marginTop = '39px';
                spanElement.style.backgroundColor = 'white'; // White background added
                spanElement.style.boxShadow = '0 2px 12px 0 rgba(0, 55, 130, 0.333)'; // Box shadow added
                spanElement.style.zIndex = '9999'; // Ensure the tooltip appears on top
                spanElement.style.padding = '10px';    // Adjust padding for mobile

                // **Responsive style for mobile**
                if (window.innerWidth <= 768) {
                    spanElement.style.maxWidth = '200px';  // Mobile width
                    spanElement.style.fontSize = '14px';  // Mobile font size
                    spanElement.style.padding = '8px';    // Adjust padding for mobile
                    spanElement.style.position = 'absolute';
                    spanElement.style.left = '68%';
                    spanElement.style.transform = 'translateX(-50%)';
                } else {
                    spanElement.style.maxWidth = '350px';  // Desktop width
                    spanElement.style.fontSize = '16px';
                    spanElement.style.position = 'absolute';
                    spanElement.style.left = 'auto';
                    spanElement.style.transform = 'none';
                }

                spanElement.innerHTML = `<div class='ref-tooltip'>${refdetaildata}</div>`;
                aTagEle.appendChild(spanElement);

                // **Ensure Tooltip is Removed on Click or Mouse Leave**
                const removeTooltip = () => {
                    spanElement.remove();
                    document.removeEventListener('click', removeTooltip);
                };

                document.addEventListener('click', removeTooltip);
                aTagEle.addEventListener('mouseleave', removeTooltip);
            }
        }
    }








    returnCitation(citation: string | null | undefined): string {
        if (!citation) return '';

        // Trim whitespace and split lines
        let lines = citation.trim();

        // Regex to find a DOI URL
        const doiRegex = /(https?:\/\/doi\.org\/[^\s]+)/g;

        // Replace DOI with a clickable anchor tag
        lines = lines.replace(
            doiRegex,
            '<a href="$1" target="_blank">$1</a>'
        );

        return lines;
    }

    //for getting the author details
    getAuthorlist(authors: any[]) {
        const fullname1: string[] = [];
        const fullname2: string[] = [];

        if (authors) {
            authors.map((element) => {
                let detail = '';
                const spanStyle = "font-weight: bold;font-size:17px";
                detail =
                    "<span class='ref-pop-heading'><span class='tooltip-author'>" +
                    element.given_name +
                    ' ' +
                    element.surname;
                if (element.role) {
                    detail += ", " + element.role +
                        '</span></span>';
                }
                if (element.email) {
                    detail +=
                        "<div class='aff'>";
                    detail += "<span class='tooltip-email'>Author Email: </span>";
                    detail += '<p><a href="mailto:' + element.email + '">' + element.email + '</a></p>';
                    detail += '</div>';
                }
                if (element.affiliation) {
                    detail +=
                        "<div class='aff'>";
                    detail += "<span class='tooltip-aff'>Affiliation: </span>";
                    detail += "<p>" + element.affiliation + '</p>';
                    detail += '</div>';
                }
                let name = "<div class='tooltips affiliation-popover-content  p-0'>";

                name +=
                    "<span style='font-weight:600;'>" +
                    element.given_name +
                    ' ' +
                    element.surname +
                    "</span>" +
                    "<span class='tooltiptext'>" +
                    detail +
                    '</span></div></div>';
                fullname1.push(name);

                const sname = element.given_name + ' ' + element.surname;
                fullname2.push(sname);
            });
        }
        return fullname1.join(', ');
    }

    authorAff(author: authors_data) {
        let detail = '<strong>' + author.given_name + ' ' + author.surname + '</strong><br>' + author.role + '<br>' + author.affiliation;
        return detail;
    }

    toggleTab() {
        this.isTabActive = !this.isTabActive;
        if (this.myButton && this.myDiv) {
            const buttonRect = this.myButton.nativeElement.getBoundingClientRect();
            //     // this.myDiv.nativeElement.style.left = `${buttonRect.right}px`;
            this.myDiv.nativeElement.style.top = `${buttonRect.top - 20}px`; // Optional, adjust based on need
            this.topValue = `${buttonRect.top - 20}px`;
            //     // this.myDiv.nativeElement.style.transition='right 0.5s ease' ;
            //     // this.myDiv.nativeElement.style.width=`auto`;
        }

    }
    scrollToOutLine() {
        if (this.articleInfo) {
            const element = this.articleInfo.nativeElement;
            const offset = 100; // Adjust this value to scroll more pixels
            const topPosition = element.getBoundingClientRect().top + window.scrollY - offset;

            window.scrollTo({
                top: topPosition,
                behavior: 'smooth',
            });
        } else {
            console.error('Target element is not available.');
        }
    }
    showPopupMessage(message: string) {
        navigator.clipboard.writeText(message).then(() => {
            this.isCopied = true;
            this.cdr.detectChanges();
            console.log('Copied:', this.isCopied);

            setTimeout(() => {
                this.isCopied = false;
                this.cdr.detectChanges();
            }, 2000);
        }).catch(err => {
            console.error('Failed to copy:', err);
        });
    }
    navigateToHome(): void {
        this.router.navigate(['/']);
    }

    ngOnDestroy() {
        this.subscriptions.forEach(x => {
            if (!x.closed) {
                x.unsubscribe();
            }
        });
        this.meta.updateTag(
            { property: 'og:site_name', content: env.siteUrl },
        );
        this.meta.updateTag(
            { property: 'og:url', content: env.siteUrl },
        );
        this.meta.updateTag(
            { property: 'og:title', content: "International Textbook of Leprosy" },
        );


        this.meta.updateTag(
            { property: 'twitter:title', content: "International Textbook of Lepros" },
        );
        this.meta.updateTag(
            { property: 'twitter:card', content: "summary" },
        );
        this.meta.updateTag(
            { property: 'twitter:url', content: env.siteUrl },
        );
    }

}