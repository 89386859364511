import { NgModule } from '@angular/core';
import { BrowserModule, provideClientHydration, withNoHttpTransferCache } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouterModule } from '@angular/router';

import { AppHeaderComponent } from './template/app-header/app-header.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideHttpClient, withFetch } from '@angular/common/http';

import { HomeModule } from './home/home.module';
import { GeneralModule } from './general/general.module';
import { SearchContextService } from './services/search-context-service';
import { ChapterModule } from './chapter/chapter.module';
import { ContributorsComponent } from './contributors/contributors.component';
import { IndexComponent } from './index/index.component';
@NgModule({
    declarations: [
        AppComponent,
        AppHeaderComponent,
        ContributorsComponent,
        IndexComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        FontAwesomeModule,
        ReactiveFormsModule,
        HomeModule,
        GeneralModule,
        ChapterModule,
        RouterModule
    ],
    providers: [
        provideClientHydration(withNoHttpTransferCache()),
        SearchContextService,
        provideHttpClient(withFetch()) // Configure HttpClient to use fetch
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
