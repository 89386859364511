import { Component, ElementRef, HostListener, ViewChild, PLATFORM_ID, Inject, ChangeDetectorRef, viewChildren, QueryList, ViewChildren, inject, OnInit, AfterViewInit } from '@angular/core';
import { Renderer2 } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { DropdownService } from '../../services/dropdown.service';
import { SharedService } from '../../services/shared.service';
import { MainHomeService } from '../../services/main-home.service';
import { ArticleService } from '../../services/article.service';
import { SearchContextService } from '../../services/search-context-service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
@Component({
    selector: 'app-header',
    templateUrl: './app-header.component.html',
    styleUrl: './app-header.component.scss'
})

export class AppHeaderComponent implements OnInit, AfterViewInit {
    @ViewChild('navbarContainer') navbarContainer!: ElementRef<HTMLElement>;
    @ViewChild('dropdownScientific') dropdownScientific: ElementRef<HTMLElement> | undefined;
    @ViewChild('joinBtn') joinBtn: ElementRef;
    @ViewChild('logoImage') logoImageTag: ElementRef;
    @ViewChild('navBar') navBar: ElementRef;
    screenWidth: number;
    mobileView = false;
    isMenuOpen = false;
    logoImage = '';
    valueSet = false;
    menuOpenAbout: boolean = false;
    menuOpenScientific = false;
    menus: any[] = [];
    menuStructure: any = {};
    categoryContent: any;
    currentMenu = 'Home';
    isModalOpen = false;
    menuStates: { [key: string]: boolean } = {};
    isHomePage: boolean = false;
    menuHeadingLoading = true;
    menuContentLoading = true;
    loaderMenu = new Array(8).fill(0);
    private readonly searchContextService = inject(SearchContextService);
    url: string = '';
    urlArray: string[] = [];
    activeUrl: string = '';
    subMenuActive = false;
    clickedSubMenuParentName = '';
    megaMenuActive: boolean;
    constructor(@Inject(PLATFORM_ID) private platformId: Object, private cdr: ChangeDetectorRef,
        private sharedService: SharedService, private el: ElementRef, private dropdownService: DropdownService,
        private mainService: MainHomeService, private articleService: ArticleService, public router: Router, private route: ActivatedRoute) {
        this.screenWidth = isPlatformBrowser(this.platformId) ? window.innerWidth : 0;
    }

    ngOnInit() {
        if (isPlatformBrowser(this.platformId)) {
            this.checkResponsive();
            window.addEventListener('scroll', this.onScroll.bind(this));
            this.sharedService.getAllThemsettingsData().subscribe(response => { // console.log("response>> ", response);
                this.logoImage = response.data?.journal_logo;
                this.valueSet = true;
                if (isPlatformBrowser(this.platformId)) {
                    if (response.data?.theme_colors)
                        Object.entries(response.data?.theme_colors).map(([key, value]) => {
                            const element = "--" + key;
                            const val = value as string;
                            document.documentElement.style.setProperty(element, val);
                        });
                }
            })
        }

        this.getMenuDetails();

        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                // console.log(this.router.url );

                this.isHomePage = this.router.url === '/';
                // console.log(this.isHomePage);
                this.isHomePage ? this.navbarContainer.nativeElement?.classList.add('position-absolute') : this.navbarContainer.nativeElement?.classList.remove('position-absolute');
                this.urlArray = event.urlAfterRedirects.split('/');
                for (const item of this.menus) {
                    const pattern = new RegExp(item.identifier.replace(/_/g, '-'));
                    if (pattern.test(this.urlArray[1]) || pattern.test(this.urlArray[2])) {
                        this.clickedSubMenuParentName = item.identifier;
                        this.menuStates[item.identifier] ? this.closeMenu(item.identifier) : null;
                        break;
                    }
                }
            }
        });
    }

    onScroll() {
        const logo = document.querySelector('#logo');
        const logo_text = document.querySelector('#logoText');
        if (window.scrollY > 0) {
            this.navbarContainer.nativeElement.classList.add('scroll-shadow');

            if (window.scrollY > 100) {
                logo.setAttribute('style', 'width: 145.14px; height: 65px; bottom:-2px; transition: height 0.3s ease;');
                logo_text.setAttribute('style', 'font-size: 24px !important;');
                if (this.navbarContainer?.nativeElement && this.navBar?.nativeElement) {
                    this.navbarContainer.nativeElement.style.height = this.navBar.nativeElement.offsetHeight + 'px';
                }
            }
        } else {
            logo.setAttribute('style', 'bottom:22px');
            logo_text.setAttribute('style', 'font-size: 28px !important;');
            this.navbarContainer.nativeElement.classList.remove('scroll-shadow');
        }
    }

    closeMenu(menu: string) {
        this.menuStates[menu] = false;
        setTimeout(() => {
            this.dropdownService.closeMenu(menu);
        }, 200);
    }

    cancelCloseMenu(menu: string) {
        this.menuStates[menu] = true;
        this.dropdownService.cancelCloseMenu(menu);
    }
    toggleMobileView() {
        this.isMenuOpen = !this.isMenuOpen;
        this.mobileView = !this.mobileView;
        if (!this.isMenuOpen) {
            this.menus.forEach(e => {
                this.menuStates[e.identifier] = false;
            });
        }
    }

    openMenu(menu: string) {
        if (!this.menuStates[menu]) this.menuStates[menu] = true;
        this.dropdownService.openMenu(menu);
    }

    @HostListener('window:resize', ['$event'])

    onResize(event: Event) {
        if (isPlatformBrowser(this.platformId)) {
            this.screenWidth = window.innerWidth;
            this.checkResponsive();
            if (this.screenWidth >= 992 && this.isMenuOpen) {
                this.toggleMobileView();
            }
        }
    }

    checkResponsive() {
        if (this.screenWidth <= 992) {
        } else {
            this.mobileView = false;
        }
    }

    isMenuOpenMenu(menu: string): boolean {
        return this.dropdownService.isOpen(menu);
    }

    toggleMenu(identifier: string) {
        this.menuStates[identifier] = !this.menuStates[identifier];
    }

    isMenuOpenfunction(identifier: string): boolean {
        return !!this.menuStates[identifier];
    }

    getMenuDetails() {
        this.mainService.getMenuDetails().subscribe((response: any) => {
            this.menus = response;

            this.menus.forEach(e => {
                this.menuStates[e.identifier] = false;

                if (e.subMenus && e.subMenus.length > 0) {
                    const data = {
                        category: e.identifier
                    }
                    this.articleService.getCategoryContent(data).subscribe((categoryResponse: any) => {
                        this.categoryContent = categoryResponse.data[0];
                        e['categoryContent'] = this.categoryContent;

                        this.menuHeadingLoading = false;
                        this.menuContentLoading = false;
                    });
                }
            });
        });
    }


    sortMenu(menu: any) {
        // Sort the menu items and their submenus based on the sort_order
        menu.subMenus.sort((a: any, b: any) => a.sort_order - b.sort_order);
        menu.subMenus.forEach((subMenu: any) => this.sortMenu(subMenu));
    }

    closeOtherMenu(menu: string) {
        // this.menuHeadingLoading=true;
        // this.menuContentLoading=true;
        for (let key in this.menuStates) {
            this.menuStates[key] = key === menu;
        }
    }

    openJoinModal() {
        this.isModalOpen = true;
    }

    closeJoinModal() {
        this.isModalOpen = false;
    }

    ngAfterViewInit(): void {
        this.cdr.detectChanges();
    }

    getClickedSubMenuParent(menu: any) {
        if (menu.url !== null) this.clickedSubMenuParentName = menu.identifier;
    }

}