import { Component, ElementRef, HostListener, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { MainHomeService } from './services/main-home.service';
import { isPlatformBrowser } from '@angular/common';
import { JournalService } from './services/journal.service';
import { Subscription } from 'rxjs';
import { SharedService } from './services/shared.service';
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
    @ViewChild('MainContentDiv') MainContentDiv: ElementRef<HTMLElement>
    title = 'site';
    menuVisible: boolean = false;
    isShowScrollTopButton = false;
    private showScrollHeight = 400;
    subscription: Subscription[] = [];

    constructor(private library: FaIconLibrary,
        private sharedService: SharedService,
        private mainService: MainHomeService,
        @Inject(PLATFORM_ID) private platformId: any,
        private journalService: JournalService,) {
        // Add all icons to the library
        this.library.addIconPacks(fas, far, fab);
    }

    ngOnInit(): void {
        const journalType = { jnl_type: "JNL" }

        const journalSub = this.journalService.getJournals(journalType).subscribe((result) => { //console.log("result>> ", result);
            this.sharedService.setJournalId(result.data[0].id);
            this.mainService.getAllThemeSettings(result.data[0].id).subscribe((result) => {

                if (result.status === 200) {
                    this.sharedService.setAllThemsettingsData(result);
                    if (isPlatformBrowser(this.platformId)) {
                        Object.entries(result.data.theme_colors).map(([key, value]) => {
                            const element = "--" + key;
                            const val = value as string;
                            document.documentElement.style.setProperty(element, val);
                        });
                    }
                }
            });
        })
        this.subscription.push(journalSub);
    }

    @HostListener('window:scroll', [])
    onWindowScroll(): void {
        const scrollPosition = window.scrollY || document.documentElement.scrollTop || document.body.scrollTop;
        if (scrollPosition > this.showScrollHeight) {
            this.isShowScrollTopButton = true;
        } else if (this.isShowScrollTopButton && scrollPosition < this.showScrollHeight) {
            this.isShowScrollTopButton = false;
        }
    }

    scrollToTop(): void {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    toggleMenu() {
        this.menuVisible = !this.menuVisible;
        const navMenu = document.querySelector('.nav ul');
        if (navMenu) {
            navMenu.classList.toggle('show', this.menuVisible);
        }
    }
}