import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { env } from '../config/config';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ContributorsService {

    constructor(
        private http: HttpClient
    ) { }

    getContributors(): Observable<any> {
        return this.http.get<any>(
            `${env.siteApiUrl}web/contributors`
        );
    }

    getExpertReview(): Observable<any>{
        return this.http.get<any>(
            `${env.siteApiUrl}web/expert-authors`
        );
    }
}
